<script>
import Layout from "../../layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from "v-money";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      "Choose a value": "Escolha um valor",
      "Or enter the amount of your donate": "Ou digite o valor da sua doação",
      "Value is required.": "Valor é obrigatório",
      "New Donate": "Nova Doação",
      Rules: "Regras",

      "My Orders": "Meus Pedidos",

      Order: "Pedido",
      Date: "Data",
      Total: "Total",
      Status: "Status",
      Payment: "Pagamento",
      Details: "Detalhes",
      Pending: "Pendente",
      Approved: "Aprovado",
      Canceled: "Cancelado",
      View: "Ver",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "Choose a value": "Elija un valor",
      "Or enter the amount of your donate":
        "Ou introduzca la cantidad de su donación",
      "Value is required.": "Se requiere valor.",
      "New Donate": "Nueva Donar",

      "My Orders": "Meus Pedidos",

      Order: "Pedido",
      Date: "Data",
      Total: "Total",
      Status: "Estado",
      Payment: "Pago",
      Details: "Detalles",
      Pending: "Pendiente",
      Approved: "Aprobado",
      Canceled: "Cancelado",
      View: "Ver",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList,
  },
  data() {
    return {
      loading: {
        token: false,
      },

      token: {
        alert: { type: "", message: "" },
        price: "0.00",
        value: "0.00",
        promotions: [],
        promotion: 0,
      },

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: "",
        precision: 2,
      },

      table: {
        heade: ["Order", "Date", "Total", "Status", ""],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    token: {
      value: { required },
    },
  },
  methods: {
    getPrice() {
      api.get("tokens/price").then((response) => {
        if (response.data.status == "success") {
          this.token.price = response.data.price;
          this.token.promotions = response.data.promotions;
        }
      });
    },
    setToken(token) {
      this.token.value = token;
    },
    buySubmit() {
      this.$v.$touch();

      if (this.token.value) {
        this.loading.token = true;

        api
          .post("tokens", {
            value: this.token.value,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.token.value = "0.00";
              this.$v.$reset();

              this.token.alert.type = "alert-success";
              this.token.alert.message = response.data.message;

              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.token.alert.type = "alert-default";
              this.token.alert.message = response.data.message;
            }

            this.loading.token = false;
          });
      }
    },
    getOrders() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("tokens/orders")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          } else {
            this.table.body = null;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
    checkPromotions() {
      const array = this.token.promotions;
      for (let i = 0; i < array.length; i++) {
        if (
          parseFloat(this.token.value.replace(/^\W|,/g, "")) >= array[i].from &&
          parseFloat(this.token.value.replace(/^\W|,/g, "")) < array[i].to
        ) {
          this.token.promotion = array[i].percent;
          break;
        } else {
          this.token.promotion = 0;
        }
      }
    },
  },
  mounted() {
    this.getPrice();
    this.getOrders();
  },
  watch: {
    "token.value": function () {
      this.checkPromotions();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Tokens") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body p-4">
            <b-form class="p-3" @submit.prevent="buySubmit">
              <div
                v-if="token.alert.message"
                :class="'alert ' + token.alert.type"
              >
                {{ t(token.alert.message) }}
              </div>
              <p class="font-weight-medium">{{ t("Choose a value") }}</p>
              <div class="mb-4">
                <a
                  class="btn btn-default text-white mr-2 mb-2"
                  @click="setToken('$10.00')"
                  >$10.00</a
                >
                <a
                  class="btn btn-default text-white mr-2 mb-2"
                  @click="setToken('$50.00')"
                  >$50.00</a
                >
                <a
                  class="btn btn-default text-white mr-2 mb-2"
                  @click="setToken('$100.00')"
                  >$100.00</a
                >
                <a
                  class="btn btn-default text-white mr-2 mb-2"
                  @click="setToken('$150.00')"
                  >$150.00</a
                >
                <a
                  class="btn btn-default text-white mr-2 mb-2"
                  @click="setToken('$200.00')"
                  >$200.00</a
                >
              </div>
              <b-form-group
                id="value"
                :label="t('Ou digite o valor que deseja comprar')"
                label-for="value"
              >
                <b-form-input
                  id="value"
                  v-model="token.value"
                  v-money="money"
                  type="text"
                  inputmode="decimal"
                  :class="{ 'is-invalid': $v.token.value.$error }"
                ></b-form-input>
                <div v-if="$v.token.value.$error" class="invalid-feedback">
                  <span v-if="!$v.token.value.required">{{
                    t("Value is required.")
                  }}</span>
                </div>
              </b-form-group>
              <div class="bg-soft-light p-3 rounded d-flex">
                <div class="align-self-center mr-2">
                  <i class="bx bxs-wallet font-size-18"></i>
                </div>
                <div>
                  Você receberá
                  <template v-if="token.price > 0">{{
                    (token.value.replace(/^\W|,/g, "") / token.price) | token
                  }}</template>
                  <template v-else>0</template>
                  tokens

                  <span
                    v-if="token.promotion > 0"
                    class="bg-light p-1 rounded"
                  >
                    +{{ token.promotion * 100 }}% =
                    {{
                      (token.value.replace(/^\W|,/g, "") / token.price +
                        (token.value.replace(/^\W|,/g, "") / token.price) *
                          token.promotion)
                        | token
                    }}
                  </span>
                </div>
              </div>
              <div class="mt-4">
                <b-button
                  :disabled="
                    loading.token == true || this.token.value === '$0.00'
                  "
                  type="submit"
                  variant="default"
                >
                  {{ t("Comprar") }}
                  <b-spinner
                    v-if="loading.token"
                    small
                    class="ml-2 align-middle"
                    variant="white"
                    role="status"
                  ></b-spinner>
                </b-button>
              </div>
              <div class="mt-4 small">
                {{ t("Rules") }}<br />
                <ul class="pl-4 mb-0">
                  <li>O valor mínimo de compra é de $10.00</li>
                  <li>
                    A cada
                    <template v-if="token.price > 0">{{
                      token.price | currency
                    }}</template>
                    <template v-else>$-.--</template>
                    é igual a 1 token
                  </li>
                </ul>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header bg-soft-dark rounded-top">Meus Pedidos</div>
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td v-for="(data, row) in td" :key="row">
                      <div v-if="row === 'id'">
                        <strong>#{{ data }}</strong>
                      </div>
                      <div v-else-if="row === 'total'">
                        {{ data | currency }}
                      </div>
                      <div v-else class="notranslate">
                        {{ data }}
                      </div>
                    </td>
                    <td class="text-right">
                      <router-link
                        :to="'/store/order/' + td.id"
                        class="btn btn-default btn-sm btn-rounded"
                      >
                        {{ t("View") }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
